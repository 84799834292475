import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60.67 66.28'>
      <defs>
        <style>{'.feature-13{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            d='M30.33 65.14L1 49.17V17.11l29.33-16 29.34 16v32.06z'
            fill='none'
            stroke='#13cb75'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path
            className='feature-13'
            d='M17.63 24.61v17.06l13.86 7.47v-1.71L19.76 40.6V25.78l-2.13-1.17z'
          />
          <path
            className='feature-13'
            d='M24.02 20.34v17.07l13.87 7.46v-1.71l-11.73-6.82V21.62l-2.14-1.28z'
          />
          <path
            className='feature-13'
            d='M30.42 17.14v17.07l13.87 7.46v-16zm11.74 21.33l-9.6-5.33v-12.8l9.6 6.4z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
