import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60.33 68'>
      <defs>
        <style>{'.feature-11{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            className='feature-11'
            d='M23.6 34a2.86 2.86 0 00-.86-2l-7.41-7.23a1.47 1.47 0 00-2.06 0 1.44 1.44 0 00-.43 1 1.41 1.41 0 00.44 1l7.4 7.23-7.4 7.23a1.42 1.42 0 00-.44 1 1.46 1.46 0 002.49 1l7.4-7.23a2.86 2.86 0 00.87-2zM32.24 41.23a3 3 0 00-4.15 0l-7.41 7.23a1.38 1.38 0 00-.43 1 1.41 1.41 0 00.43 1 1.48 1.48 0 002.05 0l7.46-7.24 7.4 7.24a1.5 1.5 0 002.06 0 1.47 1.47 0 00.43-1 1.42 1.42 0 00-.43-1zM39.64 34l7.41-7.23a1.44 1.44 0 00.43-1 1.4 1.4 0 00-.43-1 1.47 1.47 0 00-2 0L37.59 32a2.85 2.85 0 000 4.1L45 43.28a1.45 1.45 0 001 .42 1.47 1.47 0 001-.42 1.4 1.4 0 00.43-1 1.44 1.44 0 00-.43-1zM28.09 26.77a3 3 0 004.15 0l7.41-7.23a1.46 1.46 0 00.43-1 1.41 1.41 0 00-.44-1 1.47 1.47 0 00-2 0l-7.46 7.23-7.41-7.23a1.48 1.48 0 00-2.05 0 1.38 1.38 0 00-.43 1 1.41 1.41 0 00.43 1z'
          />
          <path
            d='M59.33 20.45a7.5 7.5 0 00-3.41-5.87L33.59 1.8a7.71 7.71 0 00-6.85 0L4.44 14.56a7.46 7.46 0 00-3.44 6v27a7.54 7.54 0 003.42 5.84l22.32 12.77a7.48 7.48 0 006.85 0L55.91 53.4a7.54 7.54 0 003.42-5.89z'
            fill='none'
            stroke='#13cb75'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
