import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 73' {...props}>
      <defs>
        <style>{'.challenge13{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path id='prefix__Fill-1' className='challenge13' d='M0 0v64h34v-2H2V2h64v31h2V0H0z' />
          <path id='prefix__Fill-2' className='challenge13' d='M19 73h15v-2H19v2z' />
          <path id='prefix__Fill-3' className='challenge13' d='M32 73h2V62h-2v11z' />
          <path id='prefix__Fill-4' className='challenge13' d='M14 25.5h29v-2H14v2z' />
          <path id='prefix__Fill-5' className='challenge13' d='M14 35h19v-2H14v2z' />
          <path id='prefix__Fill-6' className='challenge13' d='M14 44.5h11v-2H14v2z' />
          <path id='prefix__Combined-Shape' className='challenge13' d='M63 51v-4l4 4 2 2H47v-2z' />
          <path
            id='prefix__Combined-Shape-Copy'
            className='challenge13'
            d='M53 58v4l-4-4-2-2h22v2z'
          />
          <path
            d='M68.82 44.09a11.43 11.43 0 00-21.64 0A11.49 11.49 0 0048.45 67h19.1a11.49 11.49 0 001.27-22.91z'
            fill='none'
            stroke='#13cb75'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
