import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 71.28 82' {...props}>
      <g data-name='\u56FE\u5C42 2'>
        <g data-name='\u56FE\u5C42 1'>
          <path
            fill='none'
            stroke='#13cb75'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M1 21v40l34.64 20 34.64-20V21L35.64 1 1 21z'
          />
          <path
            d='M50.44 52.55a3 3 0 00-.9.15L40 43.19a12.1 12.1 0 00-11.22-19.32 1.51 1.51 0 10.41 3 9.07 9.07 0 012.47 18 9.08 9.08 0 01-9.75-12.17 1.51 1.51 0 00-2.83-1.05A12.1 12.1 0 0030.4 48a11.59 11.59 0 001.66-.12 12 12 0 005.85-2.49l9.59 9.59a3.18 3.18 0 00-.06.62 3 3 0 103-3z'
            fill='#13cb75'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
