import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 71' {...props}>
      <defs>
        <style>{'.challenge11{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path id='prefix__Fill-1' className='challenge11' d='M0 0v60h79v-2H2V2h76v58h2V0H0z' />
          <path id='prefix__Fill-2' className='challenge11' d='M23 71h35v-2H23v2z' />
          <path id='prefix__Fill-3' className='challenge11' d='M40 71h2V58h-2v13z' />
          <path id='prefix__Fill-4' className='challenge11' d='M12 16h47v-2H12v2z' />
          <path id='prefix__Fill-5' className='challenge11' d='M12 23h35v-2H12v2z' />
          <path id='prefix__Fill-6' className='challenge11' d='M12 31h25v-2H12v2z' />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
