import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60.67 66.28'>
      <g data-name='\u56FE\u5C42 2'>
        <g data-name='\u56FE\u5C42 1'>
          <path
            d='M30.33 65.14L1 49.17V17.11l29.33-16 29.34 16v32.06z'
            fill='none'
            stroke='#13cb75'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path
            d='M16.88 25.62v15l13.74 7.5 13.76-7.5v-15l-13.76-7.5zm12.5 19.25l-10-5.5v-10l10 5.5zm12.5-5.5l-10 5.5v-10l10-5.5zm-11.26-6.25l-11.24-6.25 11.24-6.25 11.26 6.25z'
            fill='#13cb75'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
