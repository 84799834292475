import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.95 66.27'>
      <defs>
        <style>{'.feature-23{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            id='prefix__Fill-6-Copy'
            d='M29 1.14l1.79 1A75.63 75.63 0 0057 10.71v29.08a17.63 17.63 0 01-9.47 15.66L29 65.14l-18.53-9.69A17.63 17.63 0 011 39.79V10.71a75.56 75.56 0 0026.18-8.59z'
            fill='none'
            stroke='#13cb75'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path
            id='prefix__Combined-Shape'
            className='feature-23'
            d='M36 28.13v-4l4 4 2 2H12v-2z'
          />
          <path
            id='prefix__Combined-Shape-Copy'
            className='feature-23'
            d='M22 38.13v4l-4-4-2-2h30v2z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
