import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 66 66'>
      <defs>
        <style>{'.feature-21{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            d='M33 65a32 32 0 1132-32 32 32 0 01-32 32z'
            fill='none'
            stroke='#13cb75'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
          />
          <path
            className='feature-21'
            d='M18.31 27.35a1.1 1.1 0 001.88-.78v-4.8l8.64 8.64a1.1 1.1 0 101.56-1.55l-8.65-8.65h4.81a1.1 1.1 0 100-2.2h-7.36a.91.91 0 00-.4 0 1.09 1.09 0 00-.79.79 1 1 0 000 .45v7.3a1.1 1.1 0 00.31.8zM46.88 38.35a1.1 1.1 0 00-1.1 1.1v4.8l-8.64-8.65a1.1 1.1 0 10-1.55 1.56l8.63 8.63h-4.8a1.1 1.1 0 000 2.2h7.45L47 48h.31l.13-.07a1.1 1.1 0 00.22-.21.86.86 0 00.07-.1l.07-.14a.19.19 0 000-.07.58.58 0 00.05-.14 1.5 1.5 0 000-.22v-7.6a1.1 1.1 0 00-.97-1.1zM28.86 35.6l-8.63 8.64v-4.8a1.1 1.1 0 00-.55-.95 1.1 1.1 0 00-1.65.95V47.06a.25.25 0 000 .08.74.74 0 000 .14.64.64 0 010 .07l.07.13.05.07.12.1h.07l.12.07H18.67a.78.78 0 00.22 0h7.45a1.1 1.1 0 000-2.2h-4.55l8.62-8.62a1.1 1.1 0 10-1.55-1.57zM48 18.82a1.09 1.09 0 00-.79-.79.91.91 0 00-.4 0h-7.35a1.1 1.1 0 100 2.2h4.8l-8.64 8.65a1.1 1.1 0 00.77 1.88 1.11 1.11 0 00.78-.33l8.64-8.64v4.8a1.1 1.1 0 001.88.78 1.1 1.1 0 00.32-.78v-7.3a1 1 0 00-.01-.47z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
