import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 86.01 86' {...props}>
      <g data-name='\u56FE\u5C42 2'>
        <g data-name='\u56FE\u5C42 1'>
          <path
            d='M43.37 1a1.57 1.57 0 011.21.54l.19.19 7 7a10.75 10.75 0 008.62 4.31h11A1.64 1.64 0 0173 14.76v13.66a5.43 5.43 0 001.65 3.77l9.76 9.73.22.22a1.13 1.13 0 01.38 1 1.18 1.18 0 01-.51.9 4.07 4.07 0 00-.82.67l-7 7a10.77 10.77 0 00-4.31 9.13v10.42a1.61 1.61 0 01-1.68 1.68H59.57a12.85 12.85 0 00-8.3 4.06L44 84.31l-.15.15a1.64 1.64 0 01-1.21.54 1.68 1.68 0 01-1.21-.52l-.17-.17-6.91-6.89a10.79 10.79 0 00-8.62-4.31H14.66a1.66 1.66 0 01-1.16-.47 1.63 1.63 0 01-.5-1.23V60.7a3.68 3.68 0 000-.47 12.71 12.71 0 00-4-8.29L1.69 44.7l-.15-.15a1.64 1.64 0 010-2.43l.3-.3 7-7.48a10.89 10.89 0 004.31-9.07V14.76a1.63 1.63 0 01.47-1.22 1.66 1.66 0 011.21-.48h10.73a5 5 0 00.77 0 16.47 16.47 0 008.06-3.66l.36-.34 7.35-7.33.17-.19A1.64 1.64 0 0143.48 1'
            fill='none'
            stroke='#13cb75'
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path
            d='M32.93 44.33h-7V50h-2.8V36H33.4v2.57h-7.48v3.19h7zm15.21-5.72H43.8V50H41V38.61h-4.32V36h11.46zm6.71 6.27V50h-2.79V36h5.7c3.34 0 5.12 1.74 5.12 4.45s-1.78 4.43-5.1 4.43zm0-6.31v3.76h2.86c1.52 0 2.31-.62 2.31-1.88s-.79-1.88-2.31-1.88z'
            fill='#13cb75'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
