import styled from 'styled-components'
import WorldMap from '../images/aspera/WorldMap'
import { typography, hideSMUp, hideSMDown } from '@renderbus/common/theme'
import Media from '@renderbus/common/theme/media'

export const AsperaGreyContainer = styled.div`
  min-height: 540px;
  margin-top: ${({ header }) => (header ? 60 : 0)}px;
  color: #fff;
  background: #202020;
  border-top: 1px solid transparent;
  ${Media.lessThan(Media.small)} {
    min-height: auto;
    border-bottom: 1px solid transparent;
  }
  &.aspera-container--dark {
    background: #131313;
  }
  &.aspera-container--light-dark {
    background: #242424;
  }
  &.overflow-hidden {
    overflow: hidden;
  }
  &.contact-us {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 380px;
    min-height: unset;
    .text {
      ${Media.lessThan(Media.small)} {
        margin: 0 40px 20px;
      }
    }
    .contact-btn-wrapper {
      button {
        margin: 60px auto;
      }
      .concat-us {
        top: 0;
        left: -213px;
        ${Media.lessThan(Media.small)} {
          top: unset;
          left: unset;
          bottom: -225px;
          z-index: 1;
        }
      }
    }
  }
`

export const FixedWidthContainer = styled.div`
  width: 1400px;
  margin: 0 auto;
  ${Media.lessThan(Media.small)} {
    width: auto;
  }
`

export const AsperaWhiteContainer = styled(AsperaGreyContainer)`
  padding-bottom: 95px;
  background: #fff;
`

export const AsperaSmallDarkContainer = styled(AsperaGreyContainer)`
  display: flex;
  align-items: center;
  min-height: 200px;
  background: #282828;
  svg {
    width: 80px;
  }
  .text {
    font-size: ${typography.text};
    line-height: 30px;
  }
  .concat-box {
    display: flex;
    margin-left: 175px;
  }
  .concat-channel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 23px;
    font-size: ${typography.text};
  }
  .concat-email {
    color: #13cb75;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    ${Media.lessThan(Media.small)} {
      gap: 25px;
    }
    button {
      margin-top: 0;
    }
  }
`

export const H1 = styled.h1`
  margin-top: 152px;
  margin-bottom: 0;
  font-size: ${typography.h1};
  line-height: 52px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
    margin-top: 42px;
    line-height: 1;
    text-align: center;
  }
`

export const H2 = styled.h2`
  max-width: 540px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
    margin: 15px 44px 0;
    text-align: center;
  }
`

export const H3 = styled.h3`
  font-size: 36px;
  text-align: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 95)}px;
  ${Media.lessThan(Media.small)} {
    font-size: 24px;
    margin: 52px 23px 0;
  }
`

export const AsperaSpeedH3 = styled(H3)`
  text-align: left;
  margin-top: 180px;
  font-size: 36px;
  max-width: 445px;
  ${Media.lessThan(Media.small)} {
    text-align: center;
    margin-left: 38px;
    margin-right: 38px;
  }
`

export const Button = styled.button`
  height: 40px;
  padding: 0 38px;
  margin-top: 35px;
  background: #13cb75;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: none;
  cursor: pointer;

  ${Media.lessThan(Media.small)} {
    display: block;
    padding: 9px 40px;
    margin: 20px auto 0;
    font-size: ${typography.text};
    border-radius: 400px;
  }
`

export const WorldMapSvg = styled(WorldMap)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 45%;
  ${Media.lessThan(Media.small)} {
    position: static;
    display: block;
    width: 90%;
    margin: 35px auto 40px;
    transform: none;
  }
`

export const SubTitleLayout1 = styled.div`
  text-align: center;
  font-size: 16px;
  color: #999999;
  margin-top: 30px;
  & ~ & {
    margin-top: 0;
  }
`

export const SubTitleContainer = styled.div`
  ${Media.lessThan(Media.small)} {
    margin: 20px 20px 0;
    text-align: ${({ mobileTextAlign }) => (mobileTextAlign ? mobileTextAlign : 'center')};
    div {
      display: inline;
      font-size: 12px;
    }
  }
`

export const SupportWrapper = styled.div`
  text-align: center;
  & ~ & {
    margin-left: 180px;
  }
  svg {
    width: 80px;
  }
  .suport11 {
    margin-top: 12px;
  }
`

export const SupportContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 74px;
  ${Media.lessThan(Media.small)} {
    flex-wrap: ${({ wrapInMobile }) => (wrapInMobile ? 'wrap' : 'nowrap')};
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 56px;
    ${SupportWrapper}{
      width: 45%;
      svg{
        width: 50px!important;
      }
    }
    ${SupportWrapper} ~ ${SupportWrapper}{
      margin-left: 0;
    }
  }
`

export const SupportName = styled.div`
  margin-top: 38px;
  font-size: ${typography.h4};
  line-height: 1;
  &.free-detect {
    margin-top: 45px;
  }

  ${Media.lessThan(Media.small)} {
    margin-top: 20px;
    &.free-detect {
      margin-top: 24px;
    }
    &.technology-support {
      margin-top: 26px;
    }
    &.challenge-name {
      font-size: 15px !important;
    }
  }
`

export const ChallengeContainer = styled(SupportContainer)`
  ${SupportWrapper} {
    .challenge-name {
      font-size: 24px;
      margin-top: 25px;
    }
  }
  ${Media.lessThan(Media.small)} {
    gap: 0;
    ${SupportWrapper} {
      width: fit-content;
      svg {
        width: 50px !important;
      }
    }
    &&& ${SupportWrapper} + ${SupportWrapper} {
      margin-left: 60px !important;
    }
  }
`

export const ChallengeCard = styled.div`
  display: flex;
  margin: 55px 0 100px 0;
  padding: 40px 0;
  background: #131313;
  border-radius: 20px;
  svg {
    width: 30%;
    margin-left: 55px;
    flex-shrink: 0;
  }

  ${Media.lessThan(Media.small)} {
    display: block;
    width: auto;
    margin: 20px 15px 42px;
    padding: 25px 0;
    svg {
      width: calc(100% - 84px);
      margin: 0 42px;
    }
  }
`
export const ContainerBox = styled.div`
  margin-left: 80px;
  ${Media.lessThan(Media.small)} {
    margin-top: 16px;
    margin-left: 0;
    padding: 0 18px;
    & ~ & {
      margin-top: 30px;
    }
  }
`

export const CardText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  &.card-text--predot {
    position: relative;
    margin-left: 10px;
    padding-right: 55px;
    &::before {
      content: '·';
      position: absolute;
      width: 10px;
      left: -10px;
      top: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    & ~ & {
      line-height: 1.3;
      white-space: normal;
    }

    &.card-text--predot {
      padding-right: 0;
    }
  }
`

export const CardTitle1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #13cb75;
  line-height: 24px;
  & + ${CardText} {
    margin-top: 34px;
  }

  ${Media.lessThan(Media.small)} {
    & + ${CardText} {
      margin-top: 12px;
    }
  }
`

export const CardTitle2 = styled.div`
  margin-top: 35px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  ${Media.lessThan(Media.small)} {
    margin-top: 18px;
  }
`

export const AsperaSpeedContainer = styled.div`
  .aspera-speed--content {
    & ~ .aspera-speed--content {
      margin-top: 20px;
    }
  }
`

export const GreyText = styled(CardText)`
  width: 515px;
  font-size: 16px;
  white-space: normal;
  & + & {
    margin-top: 30px;
  }
  ${Media.lessThan(Media.small)} {
    &.aspera-speed--text {
      line-height: 1;
    }
  }
`

export const AsperaFeatureBox = styled.div`
  display: flex;
  width: 30%;
  min-height: 180px;
  background: linear-gradient(0deg, #222222 0%, #303030 100%);
  border-radius: 20px;
  padding: 0 30px;
  .svg-wrapper {
    flex-shrink: 0;
    width: 60px;
    height: 68px;
    margin-top: 45px;
  }
  .feature-box {
    margin-left: 25px;
  }
  .title {
    margin-top: 46px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
  }
  .content {
    margin-top: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 28px;
  }

  ${Media.lessThan(Media.small)} {
    width: calc(100% - 30px);
    height: auto;
    padding: 0 20px 12px;
    margin: 0 15px;
    min-height: 90px;
    .svg-wrapper {
      width: 30px;
      margin-top: 20px;
      height: auto;
    }

    .feature-box {
      margin-left: 12px;
    }
    .title {
      margin-top: 20px;
      font-size: ${typography.text};
      line-height: 1;
    }
    .content {
      margin-top: 10px;
      font-size: ${typography.textThin};
      line-height: 1.5;
    }
  }
`

export const InternalContainer = styled.div`
  position: relative;
  min-height: 540px;
  margin: 0 auto;
  overflow: visible;
  border-top: 1px solid transparent;

  ${Media.lessThan(Media.small)} {
    width: auto;
    min-height: auto;
  }
`

export const AsperaSpeedTableWrapper = styled.div`
  margin: 45px 0 100px;
  ${Media.lessThan(Media.small)} {
    width: calc(100% - 30px);
    margin: 24px auto 42px;
    overflow-x: scroll;
  }
`

export const AsperaSpeedTable = styled.table`
  width: 100%;
  table-layout: fixed;
  tr {
    height: 54px;
    padding: 0 50px;
    font-size: 14px;
    color: #cccccc;
    background: #282828;
    &:nth-child(2n + 1) td {
      background-color: #202020;
    }
    &:nth-child(2n) td {
      background-color: #282828;
    }
  }

  td {
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
  }

  tr.aspera-speed-table-header td {
    color: #fff;
    background-color: #13cb75;
  }

  td:first-child {
    padding-left: 50px;
  }

  tr:not(:first-child) td:nth-child(7) {
    font-weight: bold;
    color: #13cb75;
  }

  ${Media.lessThan(Media.small)} {
    width: auto;
    margin: 0;
    overflow-x: scroll;
    table-layout: fixed;
    tr {
      height: 38px;
    }
    td:first-child {
      padding-left: 13px;
    }
    td {
      min-width: 90px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`

export const InternalFlexContainer = styled(InternalContainer)`
  display: flex;
  &.flex-container--aspera-speed {
    justify-content: center;
    min-height: auto;
    margin-bottom: 118px;
  }
  &.contact-us {
    gap: 365px;
    min-height: 0;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
    ${Media.lessThan(Media.small)} {
      margin: 40px auto;
      gap: 0;
    }
    button {
      margin: 0;
    }
  }

  ${Media.lessThan(Media.small)} {
    flex-direction: ${({ mobileDirection }) => (mobileDirection ? mobileDirection : 'row')};
    &.flex-container--aspera-speed {
      min-height: auto;
      margin-bottom: 54px;
    }
  }
`

export const AsperaSpeedFlexContainer = styled(InternalFlexContainer)`
  margin-top: 45px;
  flex-wrap: wrap;
  gap: 20px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 56px;
    gap: 10px;
  }
`

export const AsperaConcatFlexContainer = styled(InternalFlexContainer)`
  padding-top: 60px;
  min-height: 200px;
  .concat-us {
    top: 0;
    left: -213px;
  }
  ${Media.lessThan(Media.small)} {
    padding-top: 0;
    margin: 42px 42px 40px;
    .concat-box {
      margin-left: 0;
      margin-top: 25px;
    }
    svg {
      width: 50px;
    }
  }
`

export const PCImage = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;
  &&&.company-pc {
    ${hideSMDown};
  }
`

export const ImageMoible = styled.img`
  &&&.company-mobile {
    display: block;
    width: calc(100% - 40px);
    margin: 30px auto 50px;
    ${hideSMUp};
  }
`

export const AsperaVideo = styled.div`
  width: 800px;
  margin: 110px 0;
  margin-left: 86px;
  background: #282828;
  border-radius: 20px;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
  }

  ${Media.lessThan(Media.small)} {
    width: calc(100% - 40px);
    height: auto;
    margin: 30px 20px 50px;
    border-radius: 10px;
  }
`

export const AsperaWechatWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -225px;
  z-index: 1;
  &.concat-us {
    top: -60px;
    left: calc(-165px - 25px - 20px);
    ${Media.lessThan(Media.small)} {
      top: unset;
      left: unset;
    }
  }
  &.free-trial {
    top: -80px;
    left: calc(-165px - 25px - 20px);
    ${Media.lessThan(Media.small)} {
      top: unset;
      left: unset;
    }
  }
`

export const AsperaConcatBoard = styled.div`
  width: 165px;
  height: 215px;
  font-size: 14px;
  text-align: center;
  color: #181818;
  background-color: #fff;
  border-radius: 10px;
`

export const AsperaWechat = styled.img`
  width: 130px;
  height: 130px;
  border: 2px solid #fff;
  border-radius: 5px;
  margin: 0 auto;
`

export const AsperaWechatClose = styled.img`
  position: absolute;
  top: 0;
  right: -35px;
  z-index: 0;
  width: 25px;
  height: 25px;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  ${Media.lessThan(Media.small)} {
    margin: 0 auto;
  }
`
