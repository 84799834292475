import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'>
      <defs>
        <style>
          {'.prefix__cls-12{fill:none;stroke:#13cb75;stroke-width:2px;stroke-miterlimit:10}'}
        </style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            d='M40 79a39 39 0 1139-39 39.05 39.05 0 01-39 39z'
            strokeLinecap='round'
            strokeLinejoin='round'
            fill='none'
            stroke='#13cb75'
            strokeWidth={2}
          />
          <path
            className='prefix__cls-12'
            d='M22.27 33.71h35.46M22.27 46.7h35.46M40 33.71v28.36M24.96 17.93L41 33.97M39 33.97l16.04-16.04'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
