import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 84 68' {...props}>
      <defs>
        <style>{'.suport11{fill:#13cb75}'}</style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            className='suport11'
            d='M39.22 43.52l8.6-25.5-2-.5-8.6 25.5 2 .5zM32.02 19.82l-9.7 10.7 9.7 10.7 1.4-1.4-8.2-9.3 8.2-9.2-1.4-1.5zM53.72 19.82l9.7 10.7-9.7 10.7-1.4-1.4 8.2-9.3-8.2-9.2 1.4-1.5z'
          />
          <circle className='suport11' cx={7} cy={10.12} r={1} />
          <path className='suport11' d='M67 66H17a1 1 0 000 2h50a1 1 0 000-2z' />
          <circle className='suport11' cx={7} cy={6.12} r={1} />
          <path
            className='suport11'
            d='M81 0H3a3 3 0 00-3 3v54a3 3 0 003 3h78a3 3 0 003-3V3a3 3 0 00-3-3zm0 58H3a1 1 0 01-1-1V3a1 1 0 011-1h78a1 1 0 011 1v54a1 1 0 01-1 1z'
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
