import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 78.11 80.59'>
      <defs>
        <style>
          {
            '.prefix__cls-13{fill:none;stroke:#13cb75;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            className='prefix__cls-13'
            d='M53.4 79.59v-10a17.1 17.1 0 0010-2.5 10.74 10.74 0 005-8.73V50.9c2.47-.8 8.39-3.28 8.72-6.24.35-2.95-6.59-13.44-10-22.45S57.49 1 35.93 1 1 19.7 1 32.19c0 16.87 11.28 27.37 11.23 31.18v16.22M36.67 1.3v26M43.34 44.3l8.66 5'
          />
          <circle className='prefix__cls-13' cx={36.17} cy={37.3} r={10} />
          <circle cx={53.67} cy={49.8} r={3} fill='#13cb75' />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
