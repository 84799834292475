import * as React from 'react'

function SvgComponent() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 66.38 68'>
      <defs>
        <style>
          {
            '.feature12{fill:none;stroke:#13cb75;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id='prefix__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
        <g id='prefix__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
          <path
            className='feature12'
            d='M15.65 6.34a30.14 30.14 0 0140.74 6.07M19.88 58.37A30.2 30.2 0 014 22.14M62.9 31.13a30.14 30.14 0 01-24.42 29.6'
          />
          <circle cx={32.78} cy={31.13} r={9.49} fill='#13cb75' />
          <circle className='feature12' cx={32.78} cy={61.29} r={5.71} />
          <circle className='feature12' cx={6.7} cy={16.93} r={5.71} />
          <circle className='feature12' cx={59.67} cy={17.28} r={5.71} />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
